.notice_title {
    margin: 0px;
    color: rgb(50, 50, 50);
    background-color: rgb(214, 214, 214);
    display: flex;
    align-items: center;
    height: 2.5rem;
}

.notice_regdate_box {
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(205, 205, 205);
}