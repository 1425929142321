.payment-bg{
  background: #e6f1df;
}

span.payment{
  font-size:  2.5rem;
  font-weight: bold;
}

span.info-label{
  font-size: 0.8rem;
}

.payment-bg .font-info-size{
  font-size: 0.9rem;
}