/* Layout Slide Bar styling */

.sidebar-co-name {
    pointer-events: none;
    -webkit-user-drag: none;
    font-size: 1.5rem;
    vertical-align: top;
}

.menu-icon {
    font-size: 30px;
    cursor: pointer;
    padding: 15px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
}

.side-menu {
    height: 100%;
    width: 8rem;
    position: fixed;
    top: 0;
    left: -250px; /* 0px(Open), -250px;(Hidden) */
    background-color: #495057;
    overflow-x: hidden;
    transition: 0.5s;
    margin-top: 60px;
    z-index: 999;
}

.side-menu ul {
    list-style-type: none;
    padding: 0;
}

.side-menu ul li {
    padding: 10px 20px;
}

.side-menu ul li a {
    color: white;
    text-decoration: none;
    display: block;
    transition: 0.3s;
}

.side-menu ul li a:hover {
    background-color: #575757;
}

.content {
    margin-left: 20px;
    padding: 20px;
    transition: margin-left 0.5s;
}

.content.active {
    margin-left: 270px; /* 메뉴가 나올 때 본문을 오른쪽으로 밀기 */
}