.date-ragne-icon{
  width: 60px;
  height: 60px;
}

.month-arrow-icon{
  width: 60px;
  height: 60px;
}
.month-arrow-icon:hover{
  zoom: 1.2;
}

.date-info-year{
  font-size: 1.5rem;
}

.date-info-month{
  font-size: 3rem;
}

input.month-input[type="month"]{
  width: 60px;
  height: 60px;
  border: 0;
}

input.month-input[type="month"]:focus{
  border: 0;
}

input.month-input[type="month"]::-webkit-calendar-picker-indicator {
  position: relative;
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 100px;
  height: 100px;
  top : 30px;
  border-width: thin;
}
