.pagination-nav {
    margin: 0 auto;
    margin-top: 1.5rem;
}

.pagination-nav .pagination {
    display: flex;
    list-style: none;
    padding: 0;
}

.pagination-nav .page-item {
    cursor: pointer;
    margin: 4px;
}

.pagination-nav .page-link {
    padding: 8px 16px;
    cursor: pointer;
    color: #3e506f;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
}

.pagination-nav .page-item.active .page-link {
    color: #ffffff;
    background-color: #3e506f;
    border-color: #3e506f;
}

.page-item.disabled .page-link {
    color: #dee2e6;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item .page-link, .page-item span,
.page-item .page-link, .page-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8392AB;
    padding: 0;
    margin: 0 3px;
    border-radius: 0% !important;
    height: 36px;
    font-size: 0.875rem;
}