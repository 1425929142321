.drag-disable{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.header-icon{
  width: 30px;
  height: 30px;
  background-position: center;
  border-radius: 0.75rem;
}

.ms-cursor-over{
  cursor: pointer;
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active{
  background-color: #f1f1f1;
}
.navbar-vertical .navbar-nav>.nav-item .nav-link:hover{
  background-color: #c5c5c5;
}

.card{
  background: #ebebeb;
}

.innerCard{
  background: #fdfdfd;
}

.custom-transition {
  transition: 0.5s;
}

.scroll-x {
  overflow-x: scroll;
}

.clear_fix::after {
  content: "";
  display: block;
  clear: both;
}

.basicSelectBox {
  border: 1px solid #495057;
  color: #495057;
  margin-left: 1rem;
}

.ft-size-rem-08{
  font-size: 0.8rem;
}

.border-black{
  border-color: rgba(var(--bs-black-rgb),var(--bs-border-opacity))!important;
}

.nav.nav-pills .nav-link.active{
  background: #0083a3;
  color : #fff;
  font-size: 1.2rem;
}
.nav.nav-pills .nav-link{
  border: solid 1px  #0083a3;
  font-size: 1rem;
}

.nav.nav-pills .nav-item{
  margin-top : 5px;
  margin-bottom: 5px;
}

.font-color-ffffff {
  color: #ffffff;
}

.font-color-0a0a0a {
  color: #0a0a0a;
}

.text-indigo {
  color: #3366cc;
}

.gray-color-e9ecef {
  background: #e9ecef;
}

.basic-aTag {
  color: #000;
  cursor: pointer;
}

.basic-aTag:hover{
  text-decoration: underline;
  color: #000;
  font-weight: bold;
}

.beige-badge {
  width: 2rem;
  height: 1rem;
  font-size: 0.5rem;
  width: 2.5rem;
  height: 1rem;
  font-size: 0.7rem;
  font-weight: bold;
  border: 1px solid #e0763c;
  background-color: #db7944;
  ont-size: 12px;
  height: 1.5rem;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  transition-duration: 0.3s;
  box-shadow: 3px 3px 3px #eeeeee;
}

.beige-badge:hover {
  color: #fff;
}

.gray-badge {
  width: 2rem;
  height: 1rem;
  font-size: 0.5rem;
  width: 2.5rem;
  height: 1rem;
  font-size: 0.7rem;
  font-weight: bold;
  border: 1px solid #6c757d;
  background-color: #6c757d;
  ont-size: 12px;
  height: 1.5rem;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  transition-duration: 0.3s;
  box-shadow: 3px 3px 3px #eeeeee;
}

.gray-badge:hover {
  color: #fff;
}

.position-fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  background-color: #EBEBEB;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media print{
  body {
    margin: 5mm; /* 페이지 내용과 용지 사이의 여백을 조정 */
  }
  div#iconWrap,footer{
    display: none;
  }  
}