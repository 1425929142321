/* div.tab-panel > span > div:hover{
  background-color: rgb(54, 54, 54)!important;
} */

div.tab-panel span div.active{
  background-color: rgb(54, 54, 54)!important;
  font-size: 1.3rem!important;
}

/**
  탭 슬라이딩을 위한 스타일 정의
**/
.tab-panel {
  overflow-x: auto;
  white-space: nowrap;
}

.tab-container {
  display: flex;
  flex-wrap: nowrap;
}

.tab-button {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    /* background-color: #4a4a4a; */
    color: white;
    border-radius: 5px;
    cursor: pointer;
    flex: 0 0 auto;
    width: 20%;
}

.tab-title {
  font-size: 16px;
}

.tab-button .active {
  background-color: #6c757d;
}